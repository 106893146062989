import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { VIN_LOOKUP_REGEX, VIN_SIZE } from '@components/AccountEnrollment/constants';
import { WebContentProps } from '@components/VinLookup/Types';
import SearchIcon from '@mui/icons-material/Search';
import { EventDataBuilder, EventType } from '@lib-components/Analytics';
import { maskVin } from '@app/components-lib/manageSubscription';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';

interface VinLookupFormProps extends WebContentProps {
  onVinSubmit: (vin: string) => void;
}

const VinLookupForm = ({ labels, onVinSubmit }: VinLookupFormProps) => {
  const { vinInputPlaceholderLabel, invalidVinErrorMsg, vinRegexErrorMsg, checkVinLabel } = labels;

  const vinLookupSchema = z.object({
    vin: z.string().min(VIN_SIZE, invalidVinErrorMsg).regex(VIN_LOOKUP_REGEX, vinRegexErrorMsg),
  });

  type VinLookupData = z.infer<typeof vinLookupSchema>;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<VinLookupData>({
    mode: 'onChange',
    resolver: zodResolver(vinLookupSchema),
  });

  const handleFormSubmit = (data: VinLookupData) => {
    onVinSubmit(data.vin);
  };

  return (
    <Box mt={4} mb={5}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid xs={12} md={8}>
            <TextField
              {...register('vin')}
              id={'vin'}
              name={'vin'}
              placeholder={vinInputPlaceholderLabel}
              error={!!errors.vin}
              helperText={errors.vin?.message}
              sx={{
                width: '100%',
                backgroundColor: 'white',
              }}
              inputProps={{
                maxLength: VIN_SIZE,
              }}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <AnalyticsButton
              sx={{ width: { xs: '100%', md: 'auto' }, minHeight: { xs: 'auto', md: '3.4em' } }}
              disabled={!isValid}
              startIcon={<SearchIcon />}
              type="submit"
              analyticsEvent={new EventDataBuilder(EventType.VinLookupClick).withArgs({
                text: checkVinLabel,
                pageName: 'vinLookup',
                vinMask: maskVin(getValues().vin),
              })}
            >
              {checkVinLabel}
            </AnalyticsButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default VinLookupForm;
