import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import ContentRenderer from '@components/ContentRenderer';
import Dropdown from '@components/Dropdown';
import useToggle from '@hooks/useToggle';

import styles from './Header.module.css';
import useIsAuthorised from '@hooks/useIsAuthorised';
import { useTheme } from '@mui/material';

type HeaderUserProps = {
  userPrefix?: string;
  navigation?: object;
  logoutLabel?: string;
  loginLabel?: string;
  pagesToDisplayLogout?: string[];
  pagesToHideTcLinkInUserDropDown?: string[];
  pagesToHideDropdown?: string[];
  className?: string;
  theme?: string;
};

export default function HeaderUser({
  userPrefix = '',
  navigation,
  logoutLabel,
  loginLabel,
  pagesToDisplayLogout = [],
  pagesToHideTcLinkInUserDropDown = [],
  pagesToHideDropdown = [],
  className = '',
}: HeaderUserProps) {
  const { account } = useSelector(({ accountReducer }) => accountReducer);
  const userLabel = `${userPrefix} ${account?.data?.userName?.givenName || ''}`;
  const isAuthenticated = useIsAuthorised();
  const location = useLocation();
  const [showTCinDropdown, setShowTCinDropdown] = useToggle(false);
  const [forceHidden, setForceHidden] = useToggle(false);
  const theme = useTheme();

  useEffect(() => {
    setShowTCinDropdown(!pagesToHideTcLinkInUserDropDown.includes(location.pathname));
    setForceHidden(pagesToHideDropdown.includes(location.pathname));
  }, [location]);

  if (!navigation) {
    return <div className={styles['Header-user']}>{userLabel}</div>;
  }

  if (!isAuthenticated) {
    return (
      <Link className={styles['Header-user']} to="/login">
        {loginLabel}
      </Link>
    );
  }
  if (forceHidden || !userLabel) {
    return null;
  }

  return (
    <Dropdown
      firstItemLabel={userLabel}
      theme={theme}
      classes={{ container: `mx-1 ${className}` }}
      sx={{ color: theme.palette.getContrastText(theme.palette.background.paper) }}
    >
      {showTCinDropdown && (
        <ContentRenderer
          name="userMenuNavigation"
          content={[navigation]}
          className={styles['Header-dropdown-navigation']}
        />
      )}
    </Dropdown>
  );
}
