import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import milliseconds from 'date-fns/milliseconds';

import { useApi } from '@api';
import { ACTION_MAP } from '@redux/dataMap';
import { useQuery } from '@tanstack/react-query';
import Loader from '@components/Loader';
import { ACCOUNT_ACTIONS } from '@redux/actions';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import useAccount from '@api/queries/useAccount';
import useVehicle from '@api/queries/useVehicle';

type ModelConnectorProps = {
  apiConnector: {
    api: string;
    errorLabel?: string;
  };
  WrappedComponent?: React.ComponentType<any>;
};

// TODO: move this to config?
const MAX_CACHE_AGE = milliseconds({ minutes: 10 });

function SubscribedPackagesWrapper(props: ModelConnectorProps) {
  const { apiConnector, WrappedComponent } = props;

  const { data, isError, isFetching, error } = useSubscribedPackages();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ACCOUNT_ACTIONS.SET_SUBSCRIPTIONS, data });
  }, [data, dispatch]);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    console.error('API Connector error', apiConnector, error);
    return <div className="error">{apiConnector.errorLabel}</div>;
  }

  return <WrappedComponent {...props} data={data} />;
}

function AccountWrapper(props: ModelConnectorProps) {
  const { apiConnector, WrappedComponent } = props;

  const { data, isError, isFetching, error } = useAccount();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ACCOUNT_ACTIONS.SET_ACCOUNT_DATA, data });
  }, [data, dispatch]);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    console.error('API Connector error', apiConnector, error);
    return <div className="error">{apiConnector.errorLabel}</div>;
  }

  return <WrappedComponent {...props} data={data} />;
}

function VehicleWrapper(props: ModelConnectorProps) {
  const { apiConnector, WrappedComponent } = props;
  const { data, isError, isFetching, error } = useVehicle();
  const { data: subscribedPkgs } = useSubscribedPackages();
  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    console.error('API Connector error', apiConnector, error);
    return <div className="error">{apiConnector.errorLabel}</div>;
  }

  return <WrappedComponent {...props} data={data} subscribedPkgs={subscribedPkgs} />;
}
function ModelConnector(WrappedComponent: React.ElementType) {
  return function ModelConnectorWrapper(props: ModelConnectorProps) {
    const { apiConnector } = props;
    const modelKey = apiConnector.api.split('/')[1];
    if (modelKey === 'subscription') {
      return <SubscribedPackagesWrapper {...props} WrappedComponent={WrappedComponent} />;
    }

    if (modelKey === 'account') {
      return <AccountWrapper {...props} WrappedComponent={WrappedComponent} />;
    }

    if (modelKey === 'vehicle') {
      return <VehicleWrapper {...props} WrappedComponent={WrappedComponent} />;
    }

    const api = useApi();

    const services: Record<string, any> = {
      contacts: api.getContacts.bind(api),
      drivers: api.getDrivers.bind(api),
      vehicleHealth: api.getVehicleHealth.bind(api),
      storeService: api.storeService,
      myCarLocation: api.getVehicleLocation.bind(api),
      paymentMethod: api.getPaymentMethod.bind(api),
      capableServices: api.getCapableServices.bind(api),
    };

    const dispatch = useDispatch();
    const foundAction: string | undefined = ACTION_MAP[modelKey];

    const locale: string | undefined = api.storeService.getLocale();

    const { data, isError, error, isFetching } = useQuery({
      queryKey: [modelKey],
      queryFn: async () => {
        const { data } = await services[modelKey](locale);
        return data;
      },
      cacheTime: MAX_CACHE_AGE,
    });

    useEffect(() => {
      if (foundAction) {
        dispatch({ type: foundAction, data });
      }
    }, [data, foundAction, dispatch]);

    if (isFetching) {
      return <Loader />;
    }

    if (isError) {
      console.error('API Connector error', apiConnector, error);
      return <div className="error">{apiConnector.errorLabel}</div>;
    }

    return <WrappedComponent data={data} {...props} />;
  };
}

export default ModelConnector;
