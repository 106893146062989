import React, { useState } from 'react';
import { Stack, Typography, TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import Button from '@components-lib/components/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  useHeadlineStyles,
  useTextStyles,
  useTextFieldStyles,
  useValidationRulesStyles,
  useActionButtonStyles,
} from './styles';
import { extractPrimaries } from '@utils/labels';
import config from '@config/config';
import getClientId from './getClientId';
import updatePassword from '@api/requests/msafety/passwordFlow/updatePassword';
import resetPassword from '@api/requests/msafety/passwordFlow/resetPassword';
import urlSearchParams from '@utils/urlSearchParams';
import {
  CommonFormAnalyticsWrapper,
  sendAnalyticsEvent,
  EventType,
  EventDataBuilder,
} from '@app/components-lib/components/Analytics';

const analyticsFormName = 'msc password flow send new password form';

const NewPassword = ({ labels }) => {
  const {
    title,
    firstSubHeader,
    firstSubDescription,
    codeLabel,
    codeLengthMessage,
    codeValidMessage,
    secondSubHeader,
    secondSubDescription,
    newPasswordLabel,
    passwordRulesHeader,
    passwordLengthMessage,
    passwordUppercaseMessage,
    passwordCharactersMessage,
    atLeastOneNumberMessage,
    noSlashesMessage,
    noMoreThan6ConsecutiveCharactersFromEmail,
    maxLength64Characters,
    doNotReusePreviousTwoPasswords,
    doNotUseNameOrUserName,
    confirmNewPasswordLabel,
    passwordsMatchMessage,
    generalBackEndError,
    noWhiteSpaces,
    resetButtonText,
    resendButtonText,
    nextPage,
    codeResentPage,
  } = extractPrimaries(labels);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const headline = useHeadlineStyles();
  const subHeader = useTextStyles({ color: '#000', textAlign: 'left', marginBottom: '0.3rem' });
  const subDescription = useTextStyles({ fontSize: '0.75rem', textAlign: 'left' });
  const textField = useTextFieldStyles();
  const validation = useValidationRulesStyles();
  const button = useActionButtonStyles();
  const resendButton = useActionButtonStyles({ borderWidth: '2px', marginTop: '1rem' });

  const history = useHistory();
  const [status, setStatus] = useState<'idle' | 'pending' | 'rejected'>('idle');
  const [resendStatus, setResendStatus] = useState<'idle' | 'pending'>('idle');
  const [invalidCodes, setInvalidCodes] = useState<string[]>([]);
  const [generalError, setGeneralError] = useState<string>('');
  const [get401Error, set401Error] = useState<string>('');

  const { platform, email } = urlSearchParams.getAll();

  const password = yup.string();

  const minLength = password.min(8, passwordLengthMessage);
  const uppercase = password.test('at least one uppercase', passwordUppercaseMessage, (value = '') =>
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').some((uppercaseLetter) => value.includes(uppercaseLetter)),
  );
  const specialChar = password.test('at least one special character', passwordCharactersMessage, (value = '') =>
    '!@#$%&*'.split('').some((char) => value.includes(char)),
  );
  const oneNumber = password.test('at least one number', atLeastOneNumberMessage, (value = '') =>
    '1234567890'.split('').some((num) => value.includes(num)),
  );
  const maxLength = password.max(64, maxLength64Characters);
  const slashRule = password.test('no slashes', noSlashesMessage, (value = '') => !value.includes('/'));
  const noSpaces = password.test('no whitespaces', noWhiteSpaces, (value = '') => !value.match(/\s/));

  const consecutiveDigitCheck = (value: String) => {
    const arr = [];
    for (let i = 0; i < email.length; i++) {
      if (email.substring(i, i + 6).length < 6) break;
      arr.push(email.substring(i, i + 6));
    }
    if (arr.includes(value)) {
      return false;
    }
    return true;
  };

  const noPasswordWithEmail = password.test(
    noMoreThan6ConsecutiveCharactersFromEmail,
    'no more than 6',
    consecutiveDigitCheck,
  );

  const code = yup.string();
  const codeLength = code.required().min(6, codeLengthMessage);
  const validCheck = code.test('do not let customer type invalid otp again', codeValidMessage, (value = '') => {
    return !invalidCodes.includes(value);
  });

  const validationSchema = yup.object().shape({
    newPassword: minLength
      .concat(maxLength)
      .concat(uppercase)
      .concat(specialChar)
      .concat(oneNumber)
      .concat(noSpaces)
      .concat(slashRule)
      .concat(noPasswordWithEmail),
    confirmPassword: yup.string().test('passwords should be same', passwordsMatchMessage, function () {
      return this.parent.newPassword === this.parent.confirmPassword;
    }),
    code: codeLength.concat(validCheck),
  });

  const ruleIcon = (isValid: boolean) =>
    isValid ? (
      <CheckIcon color="primary" sx={{ maxWidth: '1.2rem' }} />
    ) : (
      <ClearIcon color="error" sx={{ maxWidth: '1.2rem' }} />
    );

  const clientId = getClientId(platform);
  const tenantId = config.getOemName().toLowerCase();

  const handleSubmit = async ({ newPassword, confirmPassword, code }, { setSubmitting, validateForm }) => {
    setStatus('pending');

    try {
      const result = await updatePassword({
        clientId,
        tenantId,
        userIdentifier: email,
        password: newPassword,
        confirmPassword,
        code,
      });
      if (result.ok) {
        sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: analyticsFormName }));
        history.replace(`/${nextPage}?hideNavigation=true`);
      } else {
        const error = await result.json();
        sendAnalyticsEvent(
          new EventDataBuilder(EventType.FormFailedEvent).withArgs({
            name: analyticsFormName,
            error: error.message,
          }),
        );
        if (error.statusCode !== 401) {
          setGeneralError(generalBackEndError);
        }
        if (error.statusCode === 401) {
          set401Error(codeValidMessage);
        }
        throw new Error();
      }
    } catch (_) {
      setInvalidCodes((prevInvalidCodes) => [...prevInvalidCodes, code]);
      setStatus('rejected');
    } finally {
      setSubmitting(false);
      validateForm();
    }
  };

  const resendCode = () => {
    setResendStatus('pending');
    resetPassword({
      clientId,
      tenantId,
      userIdentifier: email,
    }).finally(() => history.push(`/${codeResentPage}?hideNavigation=true`));
  };
  return (
    <CommonFormAnalyticsWrapper name={analyticsFormName}>
      <Stack p="1rem">
        <Typography variant="h1" component="h1" className={headline.headline}>
          {title}
        </Typography>
        <Formik
          initialValues={{ newPassword: '', confirmPassword: '', code: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form style={{ width: '100%', height: '100%' }}>
                <Stack width={1} height={1} justifyContent="space-between">
                  <Stack>
                    <Typography component="h2" className={subHeader.text}>
                      {firstSubHeader}
                    </Typography>
                    <Typography component="p" className={subDescription.text}>
                      {firstSubDescription}
                    </Typography>
                    <Field name="code">
                      {({ field, meta }) => {
                        const isError = !!meta.error && field.value !== meta.initialValue;
                        return (
                          <>
                            <TextField
                              label={codeLabel}
                              variant="standard"
                              error={isError}
                              inputProps={{
                                maxLength: 6,
                                onBlur: () => {
                                  set401Error('');
                                  setInvalidCodes([]);
                                },
                                onChange: () => {
                                  set401Error('');
                                  setInvalidCodes([]);
                                },
                              }}
                              className={textField.field}
                              sx={{
                                label: {
                                  marginLeft: '1rem',
                                },
                                div: {
                                  paddingLeft: '1rem',
                                },
                              }}
                              InputProps={{
                                endAdornment: isError && (
                                  <InputAdornment position="end">
                                    <InfoRoundedIcon sx={(theme) => ({ color: theme.palette.error.light })} />
                                  </InputAdornment>
                                ),
                              }}
                              {...field}
                            />
                            <ul className={validation.list}>
                              {[[codeLengthMessage, codeLength.isValidSync(props.values.code)]].map(
                                ([rule, isValid]) => {
                                  return (
                                    <li key={rule}>
                                      <Stack direction="row" alignItems="center">
                                        {props.dirty ? (
                                          ruleIcon(isValid)
                                        ) : (
                                          <FiberManualRecordIcon
                                            sx={(theme) => ({ maxWidth: '0.4rem', color: theme.palette.grey[700] })}
                                          />
                                        )}
                                        <Typography
                                          component="span"
                                          className={validation.rule}
                                          sx={(theme) => ({
                                            ...(field.value !== meta.initialValue && {
                                              color: isValid ? theme.palette.primary.main : theme.palette.error.light,
                                            }),
                                            ...(field.value === meta.initialValue && {
                                              color: theme.palette.grey[700],
                                            }),
                                          })}
                                          title={isValid ? 'valid' : 'invalid'}
                                        >
                                          {rule}
                                        </Typography>
                                      </Stack>
                                    </li>
                                  );
                                },
                              )}
                              <li>
                                <Stack direction="row" alignItems="center">
                                  {get401Error !== '' ? (
                                    ruleIcon(false)
                                  ) : (
                                    <FiberManualRecordIcon
                                      sx={(theme) => ({
                                        display: get401Error === '' ? 'none' : '',
                                        maxWidth: '0.4rem',
                                        color: theme.palette.grey[700],
                                      })}
                                    />
                                  )}
                                  <Typography
                                    component="span"
                                    className={validation.rule}
                                    sx={(theme) => ({
                                      ...(field.value !== meta.initialValue && {
                                        color: theme.palette.error.light,
                                      }),
                                      ...(field.value === meta.initialValue && { color: theme.palette.grey[700] }),
                                    })}
                                  >
                                    {get401Error}
                                  </Typography>
                                </Stack>
                              </li>
                            </ul>
                          </>
                        );
                      }}
                    </Field>
                    <Typography component="h2" className={subHeader.text}>
                      {secondSubHeader}
                    </Typography>
                    <Typography component="p" className={subDescription.text}>
                      {secondSubDescription}
                    </Typography>
                    <Field name="newPassword">
                      {({ field, meta }) => {
                        const isError = !!meta.error && field.value !== meta.initialValue;
                        const codeRules = [
                          [passwordLengthMessage, minLength.isValidSync(props.values.newPassword)],
                          [passwordUppercaseMessage, uppercase.isValidSync(props.values.newPassword)],
                          [atLeastOneNumberMessage, oneNumber.isValidSync(props.values.newPassword)],
                          [passwordCharactersMessage, specialChar.isValidSync(props.values.newPassword)],
                          [noWhiteSpaces, noSpaces.isValidSync(props.values.newPassword)],
                        ];

                        if (!maxLength.isValidSync(field.value)) {
                          codeRules.push([maxLength64Characters, maxLength.isValidSync(field.value)]);
                        }
                        if (!slashRule.isValidSync(field.value)) {
                          codeRules.push([noSlashesMessage, slashRule.isValidSync(field.value)]);
                        }
                        if (!noPasswordWithEmail.isValidSync(field.value)) {
                          codeRules.push([
                            noMoreThan6ConsecutiveCharactersFromEmail,
                            noPasswordWithEmail.isValidSync(field.value),
                          ]);
                        }
                        return (
                          <>
                            <TextField
                              label={newPasswordLabel}
                              variant="standard"
                              type={showPassword ? 'text' : 'password'}
                              error={isError}
                              className={textField.field}
                              sx={{
                                label: {
                                  marginLeft: '1rem',
                                },
                                div: {
                                  paddingLeft: '1rem',
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                ),
                              }}
                              {...field}
                            />
                            <Typography component="span" className={validation.header}>
                              {passwordRulesHeader}
                            </Typography>
                            <ul className={validation.list}>
                              <li role="listItem" style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon
                                  sx={(theme) => ({ maxWidth: '0.4rem', color: theme.palette.grey[700] })}
                                />
                                <Typography component="span" className={validation.rule}>
                                  {doNotReusePreviousTwoPasswords}
                                </Typography>
                              </li>
                              <li role="listItem" style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon
                                  sx={(theme) => ({ maxWidth: '0.4rem', color: theme.palette.grey[700] })}
                                />
                                <Typography component="span" className={validation.rule}>
                                  {doNotUseNameOrUserName}
                                </Typography>
                              </li>
                              {codeRules.map(([rule, isValid]) => {
                                return (
                                  <li role="listItem" key={rule}>
                                    <Stack direction="row" alignItems="center">
                                      {field.value !== meta.initialValue ? (
                                        ruleIcon(isValid)
                                      ) : (
                                        <FiberManualRecordIcon
                                          sx={(theme) => ({ maxWidth: '.4rem', color: theme.palette.grey[700] })}
                                        />
                                      )}
                                      <Typography
                                        component="span"
                                        className={validation.rule}
                                        sx={(theme) => ({
                                          ...(field.value !== meta.initialValue && {
                                            color: isValid ? theme.palette.primary.main : theme.palette.error.light,
                                          }),
                                          ...(field.value === meta.initialValue && { color: theme.palette.grey[700] }),
                                        })}
                                      >
                                        {rule}
                                      </Typography>
                                    </Stack>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        );
                      }}
                    </Field>
                    <Field name="confirmPassword">
                      {({ field, meta }) => {
                        const isError = !!meta.error && field.value !== meta.initialValue;
                        return (
                          <>
                            <TextField
                              label={confirmNewPasswordLabel}
                              variant="standard"
                              type={showPassword ? 'text' : 'password'}
                              error={isError}
                              className={textField.field}
                              sx={{
                                label: {
                                  marginLeft: '1rem',
                                },
                                div: {
                                  paddingLeft: '1rem',
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                ),
                              }}
                              {...field}
                            />
                            <ul className={validation.list}>
                              <li>
                                <Stack direction="row" alignItems="center">
                                  {field.value !== meta.initialValue ? (
                                    ruleIcon(!meta.error)
                                  ) : (
                                    <FiberManualRecordIcon
                                      sx={(theme) => ({ maxWidth: '0.4rem', color: theme.palette.grey[700] })}
                                    />
                                  )}
                                  <Typography
                                    component="span"
                                    className={validation.rule}
                                    sx={(theme) => ({
                                      ...(field.value !== meta.initialValue && {
                                        color: !meta.error ? theme.palette.primary.main : theme.palette.error.light,
                                      }),
                                      ...(field.value === meta.initialValue && { color: theme.palette.grey[700] }),
                                    })}
                                  >
                                    {passwordsMatchMessage}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                  {generalError !== '' ? (
                                    ruleIcon(false)
                                  ) : (
                                    <FiberManualRecordIcon
                                      sx={(theme) => ({
                                        display: generalError === '' ? 'none' : '',
                                        maxWidth: '0.4rem',
                                        color: theme.palette.grey[700],
                                      })}
                                    />
                                  )}
                                  <Typography
                                    component="span"
                                    className={validation.rule}
                                    sx={(theme) => ({
                                      ...(field.value !== meta.initialValue && {
                                        color: theme.palette.error.light,
                                      }),
                                      ...(field.value === meta.initialValue && { color: theme.palette.grey[700] }),
                                    })}
                                  >
                                    {generalError}
                                  </Typography>
                                </Stack>
                              </li>
                            </ul>
                          </>
                        );
                      }}
                    </Field>
                  </Stack>
                  <Stack>
                    <Button
                      type="submit"
                      className={button.actionButton}
                      disabled={!props.isValid || props.isSubmitting || !props.dirty}
                    >
                      {status === 'pending' ? <CircularProgress color="secondary" size={30} /> : resetButtonText}
                    </Button>
                    <Button className={resendButton.actionButton} variant="outlined" onClick={resendCode}>
                      {resendStatus === 'pending' ? <CircularProgress color="primary" size={30} /> : resendButtonText}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </CommonFormAnalyticsWrapper>
  );
};

export default NewPassword;
