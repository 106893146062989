import React from 'react';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
  useFlowMachineContextSubscriptionProps,
} from '@lib-appRouter/RouterContext';
import ManagePromoPackageList from './ManagePromoPackageList';
import { getPackagesMatchingTrials, hasPackageAdded, hasTrialEligiblePackages } from '@manageSubscription';
import { EligiblePackageInfo } from '../Types';
import { FlowEventName } from '@app/components-lib/Router/flowTypes';
import { ActionTypes } from '@app/components-lib/Router/flowSessionStorage';

const ManagePromoPackagesContainer = () => {
  const flowMachine = useFlowMachine();
  const location = useFlowLocation();
  const {
    maxSelectedPromoPackages,
    disableWithoutPromoPackages,
    eligiblePromoPackages,
    eligibleTrialPackages,
    hideBackButtonOnPaid,
    allowMultiplePromoPackagesSelection,
  } = useFlowMachineContext();
  const {
    customerCareCancelPackageNumber,
    vehicleDetails: { make },
    locale,
    tenantId,
    shouldMatchTrialWithCCRequired,
  } = useFlowMachineContextSubscriptionProps();
  const { packageSubscriptions } = useFlowMachineContextFlowStorage();
  const { commonWebContent, promoPackagesWebContent, packagesWebContent, trialPackagesWebContent, assets } =
    useFlowMachineContextContent();

  const filteredPromoPackages = getPackagesMatchingTrials(
    eligiblePromoPackages,
    packageSubscriptions,
    shouldMatchTrialWithCCRequired,
  );
  const isTrialEligible = hasTrialEligiblePackages(packageSubscriptions);
  const hasEligibleTrialPackages = eligibleTrialPackages?.length > 0;

  const navigate = (isPromoPackageSelected: boolean) => {
    isPromoPackageSelected ? flowMachine.send({ type: 'NAVIGATE_OR_SKIP' }) : flowMachine.send({ type: 'NAVIGATE' });
  };

  const navigateBack = () => {
    flowMachine.send({ type: 'NAVIGATE_BACK' });
  };

  const onPromoSelection = (promoPackage: EligiblePackageInfo) => {
    const isPackageSelected = hasPackageAdded(packageSubscriptions, promoPackage);
    flowMachine.send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: isPackageSelected ? ActionTypes.RemovePackage : ActionTypes.AddPromoPackage,
        payload: promoPackage,
        promoPackages: filteredPromoPackages,
        allowMultiplePromoPackagesSelection,
      },
    });
  };

  return (
    <ManagePromoPackageList
      assets={assets}
      customerCareCancelPackageNumber={customerCareCancelPackageNumber}
      packageSubscriptions={packageSubscriptions}
      promoPackagesWebContent={promoPackagesWebContent}
      packagesWebContent={packagesWebContent}
      promoPackages={filteredPromoPackages}
      commonWebContent={commonWebContent}
      trialPackagesWebContent={trialPackagesWebContent}
      isTrialEligible={isTrialEligible}
      canShowBackButton={!hideBackButtonOnPaid && hasEligibleTrialPackages}
      location={location}
      navigate={navigate}
      navigateBack={navigateBack}
      send={flowMachine.send}
      make={make}
      locale={locale}
      tenantId={tenantId}
      disable={disableWithoutPromoPackages}
      maxSelectedPackages={maxSelectedPromoPackages}
      onPromoSelection={onPromoSelection}
    />
  );
};

export default ManagePromoPackagesContainer;
